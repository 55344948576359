<template>
    <div class="banner">
            <div class="banner__inner">
                <div class="banner__content">
                    <h1 class="banner__heading">ПРИНИМАЕМ заказы<br>НА доставку цветов</h1>
                    <button class="banner__link" @click="selectMarch">Выбрать букет</button>
                    <!-- <p class="banner__notification">при покупке от 3 500 рублей</p> -->
                    <!-- <router-link to="/catalog" class="banner__link">Выбрать букет</router-link> -->
                </div>
            </div>
        </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    methods: {
        ...mapActions([
            'SELECT_CATEGORY',
            'GET_MORE_PRODUCTS',
            'RESET_PAGE_NUMBER',
            'RESET_SEARCH_VALUE'
        ]),
        selectMarch(){
            // this.SELECT_CATEGORY('8 марта');
            // this.RESET_PAGE_NUMBER();
            // this.RESET_SEARCH_VALUE();
            // this.GET_MORE_PRODUCTS();
            this.$router.push('/catalog')
        }
    },
}
</script>

<style scoped>
    .banner__inner{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 700px;
        color: #fff;
        background: url(../images/banner.png) no-repeat;
        background-size: cover;
    }
    .banner__content{
        max-width: 1000px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .banner__heading{
        font-size: 85px;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 50px;
    }
    .banner__notification{
        font-size: 24px;
        margin-bottom: 50px;
    }
    .banner__link{
        text-decoration: none;
        color: #fff;
        padding: 15px 40px;
        border: 1px solid #fff;
        font-size: 20px;
        text-transform: uppercase;
        transition: all linear .2s;
    }
    .banner__link:hover{
        background-color: rgba(255, 255, 255, .3);
        cursor: pointer;
    }
    @media (max-width: 768px){
        .banner__heading{
            font-size: 38px;
        }
    }
    @media (max-width: 600px){
        .banner__inner{
            display: block;
            min-height: auto;
            height: 60vh;
        }
        .banner__content{
            padding: 45px 15px 35px;
            height: 100%;
        }
        .banner__heading{
            margin-bottom: 20px;
        }
        .banner__notification{
            font-size: 16px;
        }
        .banner__link{
            width: 100%;
            text-align: center;
            margin-top: auto;
        }
    }
</style>